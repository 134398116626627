import React from 'react'
import { Table } from 'antd';
import './DashboardTable.scss'

const DashboardTable = ({data, columns, onRow}) => {
  return (
    <Table columns={columns} dataSource={data} pagination={false} onRow={onRow} />
  )
}

export default DashboardTable