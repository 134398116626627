import React from "react";
import "./Input.scss";

const TextField = ({
  type,
  name,
  register,
  errorMsg,
  placeholder,
  autocomplete,
}) => {
  return (
    // <div className="inputWrapper">
    // <div className="inpDiv">
    <input
      type={type}
      name={name || ""}
      placeholder={placeholder}
      // autocomplete={autocomplete}
      // {...(register && register)}
    />
    // {/* </div> */}
    // {errorMsg && <span className="hasError">{errorMsg}</span>}
    // </div>
  );
};

export default TextField;
