import axios from "./../libs/axios";

class TwilioService {
  constructor() {}

  token(payload) {
    return axios.get(`twillio/token`, payload);
  }
  activeRooms() {
    return axios.get(`twillio/rooms/active`);
  }
  createRoom() {
    return axios.get(`twillio/room/create`);
  }
  joinRoom(payload){
    return axios.post(`twillio/room/join`,payload);

  }
  endRoom(payload){
    return axios.post(`twillio/room/end`,payload);

  }
  paymentHold(payload){
    return axios.post(`twillio/room/update`,payload);

  }
}

export default new TwilioService();
