import CardWrapper from "../../components/card/CardWrapper";

const Unauthorized = () => {
  return (
    <div className="dashboard-login">
      <CardWrapper>
        <div className="login-card-content px-3">
          <h3 className="font-semibold darkGray text-3xl mb-2">401</h3>
          <h3 className="font-semibold darkGray text-3xl mb-5">Unauthorized</h3>
          <p className='darkBlue text-lg font-normal'>You do not have permission to access this page.</p>
        </div>
      </CardWrapper>
    </div>
  );
};
export default Unauthorized;


