import React, { useState } from "react";
import { loginSchema } from "../../schemas/validation";
import { Alert } from "antd";
import { email, eye, lockEye, logo, password } from "../../assets/images/project-images";
import CardWrapper from "../../components/card/CardWrapper";
import PrimaryButton from "../../components/button/PrimaryButton";
import "./Login.scss";
import { useForm } from "react-hook-form";
import logo00 from "../../assets/images/logo00.jpg";
import axios from "../../libs/axios";
// import axios from "axios";

import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "../../components/input-field";
import ForgotPassword from "./Forgot";
import AuthService from "../../services/auth";
import { useDispatch } from "react-redux";
import { setUser, setRefreshToken, setToken } from "../../store/Auth/authSlice";
import { setCookie } from "../../libs/cookie";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const Login = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const handleShowPassword = () => {
    setShow(!show);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });
  // const { mutate, isLoading, isError, error } = useMutation(AuthService.login, {
  //   onSuccess: (data) => {
  //     const { user, token } = data;
  //     dispatch(setUser(user));
  //     dispatch(setToken(token.access));
  //     dispatch(setRefreshToken(token.refresh));
  //     // dispatch(setRoom(room));
  //     setCookie("access_token", token.access);
  //     setCookie("refresh_token", token.refresh);
  //     queryClient.invalidateQueries("user");
  //   },
  // });

  const onSubmit = async (data) => {
    console.log(data);
    try {
      const response = await axios.post("/auth/login", data);
      console.log(response?.data);
      dispatch(setUser(response?.data));
      dispatch(setToken(response?.data?.token));
      setCookie("access_token", response?.data?.token);
      //     setCookie("refresh_token", token.refresh);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="dashboard-login">
      <CardWrapper>
        <div className="login-card-content">
          <img
            src={logo00}
            alt="logo"
            style={{
              width: "159px",
              borderRadius: "11px",
              position: "relative",
              left: "91px",
              marginBottom: "20px",
            }}
          />

          <h4 className="font-normal darkGray">Sign In</h4>
          {/* {isError && <Alert message={error.message} type="error" showIcon />} */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login-input">
              <img src={email} alt="email-icon" />
              <input
                type="text"
                {...register("email")}
                name={"email"}
                placeholder="Email"
                // autocomplete="off"
              />
              <p className="error">{errors.email?.message}</p>
            </div>
            <div className="login-input">
              <img src={password} alt="password-icon" />
              <input
                name={"password"}
                type={show ? "text" : "password"}
                {...register("password")}
                placeholder="Password"
                // autocomplete="off"
              />
              <img
                className="lock-eye"
                onClick={handleShowPassword}
                src={show ? eye : lockEye}
                alt="eye-icon"
              />
              <p className="error">{errors.password?.message}</p>
            </div>
            <div className="forgot-password" onClick={() => setIsModalOpen(true)}>
              {/* <p className="text-lg blue font-bold cursor-pointer">
                Forgot Password
              </p> */}
            </div>
            <div className="input-checked">
              <label className="darkGray text-base font-bold checkbox-container">
                <TextField type="checkbox" />
                <span className="checkmark"></span>
                Keep me signed in
              </label>
            </div>
            <div className="login-button">
              <PrimaryButton
                type="submit"
                title="sign in"
                // isLoading={isLoading}
                // disabled={isLoading}
              />
            </div>
            {/* <div className="create-account">
              <Link
                to="/sign-up"
                className="text-lg blue font-bold uppercase border-b-2 border-blue-600 border-solid"
              >
                Create account
              </Link>
            </div> */}
          </form>
        </div>
      </CardWrapper>
      <ForgotPassword isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
};

export default Login;
