import * as yup from "yup";

export const loginSchema = yup.object({
  email: yup.string().email().required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"),
});
export const registerSchema = yup.object({
  email: yup.string().email().required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
export const forgotSchema = yup.object({
  email: yup.string().email().required("Email is required"),
});
export const joinMeeitngSchema = yup.object({
  meeting_link: yup.string().required("Meeting Link is required"),
});
export const BankDetailsSchema = yup.object({
  country: yup.string().required("Country is required"),
  currency: yup.string().required("Currency is required"),
  // iban: yup.string().required("iban is required"),
  routing_number: yup.string().required("iban is required"),
  account_number: yup.string().required("Account number is required"),
  confirm_account: yup
    .string()
    .oneOf([yup.ref("account_number"), null], "Account number must match")
    .required("Confirm Account Number is required"),
});

export const userDetailsSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  // iban: yup.string().required("iban is required"),
  email: yup.string().required("Email is required"),
  phone: yup.string().required("Phone is required"),
  address: yup.string().required("Address is required"),
});
