import React, { useState } from "react";
import {
  email,
  eye,
  lockEye,
  logo,
  password,
} from "../../assets/images/project-images";
import CardWrapper from "../../components/card/CardWrapper";
import PrimaryButton from "../../components/button/PrimaryButton";
import "./Login.scss";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from "../../schemas/validation";
import { useMutation, useQueryClient } from "react-query";
import AuthService from "../../services/auth";
import { Alert, Radio } from "antd";

const Signup = () => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("user");
  const queryClient = useQueryClient();

  const handleShowPassword = (event) => {
    setShow(!show);
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerSchema),
  });
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    AuthService.register,
    {}
  );
  const onSubmit = async (data) => {
    const updateFormData = {
      ...data,
      ["role"]: value,
    };
    mutate(updateFormData);
  };
  return (
    <div className="dashboard-login">
      <CardWrapper>
        <div className="login-card-content">
          <img className="login-card-logo" src={logo} alt="logo" />
          <h4 className="font-normal darkGray">Sign Up</h4>
          {isError && <Alert message={error.message} type="error" showIcon />}
          {isSuccess && (
            <Alert message="Registered successfully" type="success" showIcon />
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login-input">
              <img src={email} alt="email-icon" />
              <input
                {...register("email")}
                type="email"
                placeholder="Email"
                // autoComplete="off"
              />
              <p className="error">{errors.email?.message}</p>
            </div>
            <div className="login-input">
              <img src={password} alt="password-icon" />
              <input
                type={show ? "text" : "password"}
                placeholder="Password"
                // autoComplete="off"
                {...register("password")}
              />
              <img
                className="lock-eye"
                onClick={handleShowPassword}
                src={show ? eye : lockEye}
                alt="eye-icon"
              />
              <p className="error">{errors.password?.message}</p>
            </div>
            <div className="login-input">
              <img src={password} alt="password-icon" />
              <input
                type={show ? "text" : "password"}
                placeholder="Confirm Password"
                // autoComplete="off"
                {...register("confirmPassword")}
              />
              <img
                className="lock-eye"
                onClick={handleShowPassword}
                src={show ? eye : lockEye}
                alt="eye-icon"
              />
              <p className="error">{errors.confirmPassword?.message}</p>
            </div>
            <div className="login-input">
              <Radio.Group
                onChange={(e) => setValue(e.target.value)}
                value={value}
              >
                <Radio value="user">Lawyer</Radio>
                <Radio value="client">Client</Radio>
              </Radio.Group>
            </div>
            <div className="login-button">
              <PrimaryButton
                type="submit"
                title="sign up"
                isLoading={isLoading}
              />
            </div>
            <div className="input-checked">
              <p className="darkGray text-base font-bold text-center">
                Already have an account?
              </p>
            </div>
            <div className="create-account">
              <Link
                to="/"
                className="text-lg blue font-bold uppercase border-b-2 border-blue-600 border-solid"
              >
                SIGN in
              </Link>
            </div>
          </form>
        </div>
      </CardWrapper>
    </div>
  );
};

export default Signup;
