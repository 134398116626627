import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { hasPermission } from "../libs/utils";
// const hasPermission = (routeRoles, userRole) => {
//   return routeRoles && routeRoles.includes(userRole);
// };
const ProtectedRoute = ({ component: Component, roles, layout: Layout, ...rest }) => {
  const { isLoggedIn, user } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          hasPermission(roles, user.role) ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect to="/unauthorized" />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default ProtectedRoute;
