export const setCookie = (name, value, minutes = 60) => {
  const { expires, token } = value;
  let date = new Date(expires);
  document.cookie = `${name}=${token}; expires=${date}; path=/`;

  // console.log(expires);
  // var date = "; expires=" + expires;
  // console.log(date);
  // document.cookie = name + "=" + token + date + "; path=/";
};

export const getCookie = (name) => {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) == " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
};

export const deleteCookie = (name) => {
  document.cookie = `${name} =; expires=Thu, 01 Jan 1970 00:00:01 GMT; Max-Age=-99999999; path=/;`;
};
