import React, { useState } from "react";
import { close, logo } from "../../assets/images/project-images";
import logo00 from "../../assets/images/logo00.jpg";
import "./Sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../routes/routes";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/Auth/authSlice";
import { hasPermission } from "../../libs/utils";
import { LogoutIcon } from "../../assets/images/project-svgs";
import { deleteCookie, setCookie } from "../../libs/cookie";

const Sidebar = ({ open, setOpen }) => {
  const [activeItem, setActiveItem] = useState(0);
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const handleItemClick = (index) => {
    setActiveItem(index);
  };
  const logoutFunction = () => {
    deleteCookie("access_token");
    deleteCookie("refresh_token");

    dispatch(logout());
  };
  return (
    <aside className={`${open ? "dashboard-sidebar tab-sidebar" : "dashboard-sidebar"}`}>
      <Link
        to={
          user.role === "admin"
            ? "dashboard"
            : user.role === "client"
            ? "/client/dashboard"
            : "/user/dashboard"
        }
      >
        <div className="dashboard-logo">
          <img className="show-tab" src={close} alt="close" onClick={() => setOpen(false)} />
          <img src={logo00} alt="logo" style={{ width: "84%", borderRadius: "11px" }} />
        </div>
      </Link>

      <ul>
        {routes.map(
          ({ title, icon, path, index, roles }) =>
            title &&
            icon &&
            hasPermission(roles, user.role) && (
              <li
                key={path}
                className={`${location.pathname === path ? "active-list" : ""}`}
                onClick={() => setOpen(false)}
              >
                <Link to={path} onClick={() => handleItemClick(index)}>
                  {icon}
                  <p className="font-semibold">{title}</p>
                </Link>
              </li>
            )
        )}
        <li className={`${activeItem ? "active-list" : ""}`} onClick={() => setOpen(false)}>
          <Link to="/" onClick={() => logoutFunction()}>
            <LogoutIcon />
            <p className="font-semibold">Logout</p>
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
