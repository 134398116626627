import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    token: null,
    isLoggedIn: false,
    refreshToken: null,
    room: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
      // state.room=
    },
    setRoom: (state, action) => {
      state.room = action.payload;
      // state.room=
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      state.isLoggedIn = false;
      state.room = null;
    },
  },
});

export const { setUser, setToken, setRefreshToken, logout, setRoom } = userSlice.actions;
export default userSlice.reducer;
