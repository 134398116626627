import React, { useEffect, useState } from "react";
import ModalWrapper from "../../components/modal/ModalWrapper";
import PrimaryButton from "../../components/button/PrimaryButton";
import axios from "../../libs/axios";
import { editProfile, profilePicture, visa } from "../../assets/images/project-images";
// import ProfileForm from "./ProfileForm";
import img from "../../assets/images/profilepic.jpg";
import img0 from "../../assets/images/default.jpg";
import { useDispatch, useSelector } from "react-redux";
import { onSucess } from "../../libs/onSuccess";
import { onError } from "../../libs/errorLib";
import { setUser } from "../../store/Auth/authSlice";
import config from "../../config";
import CardForm from "./creditForm";
import Transactions from "./Transactions";
import CardDetail from "./cardDetail";

const CardEdit = ({
  isModalOpen,
  userData,
  getCardsData,
  cardDetail,
  setcardDetail,
  setisModalOpen,
  active,
  setactive,
  catData,
  message,
  transaction,
  settransaction,
  categories,
  id,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const [loader, setloader] = useState(false);
  const [image, setimage] = useState(null);

  const onFileChange = (event) => {
    setimage(event.target.files[0]);
  };

  const changeAccess = async () => {
    setloader(true);
    try {
      await axios.put(`/card/access/${id?.id}`, { isActive: !id?.isActive });
      onSucess("Access changed successfully");
      getCardsData();
      setactive(false);
    } catch (error) {
      onError("Something Went Wrong");
      console.error("Error fetching data:", error?.message);
    }
    setisModalOpen(false);
    setloader(false);
  };

  return (
    <ModalWrapper
      isModalOpen={isModalOpen}
      className="account-edit-modal"
      settransaction={settransaction}
      setisModalOpen={setisModalOpen}
      setactive={setactive}
      Modalfooter={[
        <>
          {active && (
            <div
              className="modal-footer-buttons"
              style={{ marginTop: "60px", display: "flex", justifyContent: "center" }}
            >
              <PrimaryButton
                isLoading={loader}
                disabled={loader}
                onClick={() => {
                  changeAccess();
                  // setisModalOpen(false);
                }}
                title="yes"
              />
              <PrimaryButton
                onClick={() => {
                  setisModalOpen(false);
                  setactive(false);
                }}
                title="cancel"
                className="secondary-button"
              />
            </div>
          )}
        </>,
      ]}
    >
      <div className="account-edit-modal-content">
        {transaction ? (
          <>
            <h3 className="blue font-light mb-4" style={{ fontSize: "29px", fontWeight: 400 }}>
              Transaction History
            </h3>
            <Transactions
              user={user}
              userData={userData}
              isModalOpen={isModalOpen}
              setisModalOpen={setisModalOpen}
              transaction={transaction}
              settransaction={settransaction}
            />
          </>
        ) : active ? (
          <div style={{ width: "70%", textAlign: "center", marginInline: "auto" }}>
            <h3 className="blue font-light text-3xl" style={{}}>
              Are you sure you want to {message} this Card?
            </h3>
          </div>
        ) : cardDetail ? (
          <>
            <h3 className="blue font-light mb-4" style={{ fontSize: "29px", fontWeight: 400 }}>
              Card Detail
            </h3>
            <CardDetail
              id={id}
              cardDetail={cardDetail}
              setcardDetail={setcardDetail}
              user={user}
              userData={userData}
              isModalOpen={isModalOpen}
              setisModalOpen={setisModalOpen}
              transaction={transaction}
              settransaction={settransaction}
            />
          </>
        ) : (
          <>
            {" "}
            <h3 className="blue font-light mb-4" style={{ fontSize: "29px", fontWeight: 400 }}>
              Add Card
            </h3>
            <div className="edit-profile-picture cursor-pointer mb-4" style={{ cursor: "pointer" }}>
              <input
                style={{ display: "none" }}
                accept="image/*"
                fullWidth={true}
                id="contained-button-file"
                onChange={onFileChange}
                type="file"
                name="image"
              />
              <label htmlFor="contained-button-file">
                <div>
                  <div>
                    <img
                      alt="not found"
                      style={{
                        width: "100%",
                        height: "100px",
                        cursor: "pointer",
                        borderRadius: "10px",
                      }}
                      src={
                        image ? URL.createObjectURL(image) : img0
                        //   : config.IMG_BASE_URL + clientform?.image
                      }
                    />
                  </div>
                </div>
              </label>
            </div>
            <CardForm
              getCardsData={getCardsData}
              user={user}
              userData={userData}
              catData={catData}
              categories={categories}
              image={image}
              isModalOpen={isModalOpen}
              setisModalOpen={setisModalOpen}
              transaction={transaction}
              settransaction={settransaction}
            />
          </>
        )}
      </div>
    </ModalWrapper>
  );
};

export default CardEdit;
