import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
const hasPermission = (routeRoles, userRole) => {
  return routeRoles && routeRoles.includes(userRole);
};
const UnAuthenticated = ({ component: Component, roles, layout: Layout, ...rest }) => {
  const { isLoggedIn, user } = useSelector(({ auth }) => auth);
  console.log(user);
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          user.role === "admin" && <Redirect to="/dashboard" />
        )
      }
    />
  );
};

export default UnAuthenticated;
