import React from 'react'
import './CardWrapper.scss'

const CardWrapper = ({children, className}) => {
  return (
    <div className={`card-wrapper ${className}`}>
      {children}
    </div>
  )
}

export default CardWrapper