import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputLabelField from "../../components/input-label-field/InputLabelField";
import SelectLabelField from "../../components/select-label-field/SelectLabelField";
import { yupResolver } from "@hookform/resolvers/yup";
import { userDetailsSchema } from "../../schemas/validation";
import InputLabelField1 from "../../components/input-label-field/inputLabelField1";
import PrimaryButton from "../../components/button/PrimaryButton";
export const selectLocation = ["lahore", "karachi", "multan"];
export const selectCurrency = ["USD", "PKR", "EUR", "IND"];

const Transactions = ({ setisModalOpen, settransaction, transaction }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loader, setloader] = useState(false);

  // {
  // resolver: yupResolver(userDetailsSchema),
  // }

  //   const onSubmit = (data) => console.log(data);

  const onSubmit = async (data) => {
    // console.log(errors);
    // console.log(data);
    // try {
    //   const response = await axios.get("/card/all");
    //   console.log(response?.data);
    // } catch (error) {
    //   console.error("Error fetching data:", error?.message);
    // }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {transaction ? (
        <>
          <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
            <InputLabelField
              register={register}
              label="First Name"
              type="text"
              name="firstName"
              placeholder="Andrew"
              required={true}
            />
            <InputLabelField
              register={register}
              label="Last Name"
              type="text"
              name="lastName"
              placeholder="Armstrong"
              required={true}
            />
          </div>
          <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
            <InputLabelField
              register={register}
              label="Email"
              type="email"
              name="email"
              placeholder="armstrong_andrew@gmail.com"
              required={true}
            />
            <InputLabelField
              register={register}
              label="Phone"
              type="phone"
              name="phone"
              placeholder="+12-123-1234567 "
              required={true}
            />
          </div>
          <div className="flex gap-4 mb-4">
            <InputLabelField
              register={register}
              label="Address"
              type="text"
              name="address"
              placeholder="Lorem ipsum dolor sit amet, consectetur"
              required={true}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
            <PrimaryButton isLoading={loader} onClick={handleSubmit} type="submit" title="Save" />
            <PrimaryButton
              onClick={() => {
                setisModalOpen(false);
                setloader(false);
                settransaction(false);
              }}
              title="cancel"
              className="secondary-button"
            />{" "}
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
            <InputLabelField
              register={register}
              label="Serial Number"
              type="text"
              name="serialNumber"
              placeholder="Enter Serial Number"
              required={true}
            />
            <InputLabelField
              register={register}
              label="Manufacturar"
              type="text"
              name="manufacurar"
              placeholder="Enter Manufacturar"
              required={true}
            />
          </div>
          <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
            <InputLabelField
              register={register}
              label="Expiry"
              type="date"
              name="expiry"
              placeholder="Enter Expiry Date"
              required={true}
            />
            <InputLabelField
              register={register}
              label="Category"
              type="text"
              name="category"
              placeholder="Enter Category"
              required={true}
            />
          </div>
          <div className="flex gap-4 mb-4">
            <InputLabelField
              register={register}
              label="Type"
              type="text"
              name="type"
              placeholder=""
              required={true}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
            <PrimaryButton isLoading={loader} onClick={handleSubmit} type="submit" title="Save" />
            <PrimaryButton
              onClick={() => {
                setisModalOpen(false);
                setloader(false);
                settransaction(false);
              }}
              title="cancel"
              className="secondary-button"
            />{" "}
          </div>
        </>
      )}
    </form>
  );
};

export default Transactions;
