import axios from "axios";
import config from "../config";
import { getCookie, deleteCookie, setCookie } from "./cookie";
import { logout } from "../store/Auth/authSlice";
import store from "../store/store";

const baseInstance = axios.create({
  baseURL: config.API_BASE_URL,
});
baseInstance.defaults.headers.common["Accept"] = "*/*";
baseInstance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
baseInstance.interceptors.request.use(function (config) {
  // const token = getCookie("access_token");
  // console.log(token);
  // if (token) {
  //   config.headers.Authorization = `Bearer ${token}`;
  // } else {
  config.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImRhbmlzaDIwMjBAZ21haWwuY29tIiwiaWQiOjQsInJvbGUiOiJhZG1pbiIsImlhdCI6MTY5MTEzNTgyOSwiZXhwIjoxNjkzMTIzMDI5fQ.IoscMQx51tcUKwVRXhNffK3BQT_it40NT83iKQNDBPI`;
  // }
  return config;
});
// baseInstance.interceptors.response.use(
//   (response) => response.data,
//   async (error) => {
//     const { config } = error;
//     console.log(config.url);
//     if (config.url !== "auth/login") {
//       if (error.response?.status === 401 && !config._retry) {
//         config._retry = true;
//         try {
//           if (!getCookie("refresh_token")) {
//             store.dispatch(logout());
//             window.location.href = "/";
//           }
//           const res = await axios.post(
//             `auth/refresh-tokens`,
//             {
//               refreshToken: getCookie("refresh_token"),
//             },
//             {
//               headers: {
//                 "Content-Type": "application/x-www-form-urlencoded",
//                 Authorization: `Basic eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3RAdmlwcHMuY29tIiwiaWQiOjMsInJvbGUiOiJ1c2VyIiwiaWF0IjoxNjkwMjgwMDM1LCJleHAiOjE2OTIyNjcyMzV9.TGEJdqlSjWSiRxPaZT6UKnS1-UtQpJqbuyjRTyFQFXI`,
//               },
//             }
//           );
//           const { access, refresh } = res.data;
//           setCookie("refresh_token", refresh);
//           setCookie("access_token", access);
//           return await baseInstance(config);
//         } catch (err) {
//           console.log("config._retry", err.response.data);

//           if (err.response.status === 401 || err.response.status === 500) {
//             deleteCookie("access_token");
//             deleteCookie("refresh_token");
//             store.dispatch(logout());
//             window.location.href = "/";
//             console.log("catch", err);
//           }
//           return await Promise.reject(err.response.data);
//         }
//       }
//     }

//     console.log("awais", error);

//     return Promise.reject(error.response.data);
//   }
// );
export default baseInstance;
