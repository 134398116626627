import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import DashboardTable from "../../components/table/DashboardTable";
import DashboardPagination from "../../components/pagination/DashboardPagination";
import { sortArrow } from "../../assets/images/project-images";
import UserService from "../../services/user";
import "./Clients.scss";
import axios from "../../libs/axios";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
// import axios from "axios";
import PrimaryButton from "../../components/button/PrimaryButton";
import { Select } from "antd";
import config from "../../config";
import CardEdit from "./cardsEdit";

const Cards = () => {
  const [cardtype, setcardtype] = useState("All");
  const [cardData, setcardData] = useState([]);
  const [categories, setcategories] = useState([]);
  const [cardDetail, setcardDetail] = useState(false);
  const [catType, setcatType] = useState("Sports");
  const [addCard, setaddCard] = useState(false);
  const [catData, setcatData] = useState([]);
  const [active, setactive] = useState(false);
  const [message, setmessage] = useState("");
  const getCardsData = async () => {
    try {
      // const response = await axios.get(`/card/listed/${catType}`);
      const response = await axios.get(`/card/all`);
      console.log(response);
      setcardData(response?.data?.data);
    } catch (error) {
      setcardData([]);
      console.error("Error fetching data:", error?.message);
    }
  };

  const getAllCategories = async () => {
    try {
      const response = await axios.get(`/category/all`);

      let data = response?.data?.data.map((item, i) => {
        return { value: item?.categoryName, label: item?.categoryName };
      });
      let data0 = response?.data?.data.map((item, i) => {
        return { value: item?.id, label: item?.categoryName };
      });
      let newCat = [
        ...data0,
        { value: "offers", label: "All Cards with offer" },
        { value: "companies", label: "All listed cards companies" },
      ];

      setcatData(data0);
      setcategories(newCat);
    } catch (error) {
      console.error("Error fetching data:", error?.message);
    }
  };

  useEffect(() => {
    getCardsData();
  }, [catType]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const columns = [
    {
      title: <div className="cursor-pointer">Brand Name</div>,
      dataIndex: "brandId",
      key: "brandId",
      render: (name) => <p className="darkBlue">{name ? name?.name : "NULL"}</p>,
    },
    {
      title: <div className="cursor-pointer">Expiry</div>,
      dataIndex: "expiry",
      key: "expiry",
    },
    {
      title: <div className="cursor-pointer">Discount</div>,
      dataIndex: "brandId",
      key: "brandId",
      render: (name) => <p className="darkBlue">{name ? name?.discount : "NULL"}</p>,
    },
    {
      title: <div className="cursor-pointer">Type</div>,
      dataIndex: "type",
      key: "type",
    },
    {
      title: <div className="cursor-pointer">Selling-Price</div>,
      dataIndex: "sellingPrice",
      key: "sellingPrice",
      render: (name) => <p className="darkBlue">{name ? name : "NULL"}</p>,
    },
    {
      title: <div className="cursor-pointer">Balance</div>,
      dataIndex: "balance",
      key: "balance",
      render: (name) => <p className="darkBlue">{name ? name : "NULL"}</p>,
    },
    {
      title: <div className="cursor-pointer">isActive</div>,
      dataIndex: "isActive",
      key: "isActive",
      render: (name) => <p className="darkBlue">{name === true ? "true" : "false"}</p>,
    },
    {
      title: "",
      dataIndex: "isActive",
      key: "isActive",
      render: (id, record) =>
        id === true ? (
          <PrimaryButton
            title="Disable"
            className="unblock-btn capitalize"
            onClick={async () => {
              setid(record);
              setisModalOpen(true);
              setactive(true);
              setmessage("disable");
              setaddCard(false);
              setcardDetail(false);
              // setaddcategory(false);
            }}
          />
        ) : (
          <PrimaryButton
            title="Enable"
            className="unblock-btn capitalize"
            onClick={async () => {
              setid(record);
              setactive(true);
              setisModalOpen(true);
              setmessage("enable");
              setaddCard(false);
              setcardDetail(false);

              // setaddcategory(false);
            }}
          />
        ),
    },
    // {
    //   title: <div className="cursor-pointer"></div>,
    //   dataIndex: "",
    //   key: "",
    //   render: (name) => (
    //     <p className="darkBlue">
    //       {cardtype === "All" ? (
    //         <h1
    //           onClick={() => {
    //             settransaction(true);
    //             setisModalOpen(true);
    //           }}
    //         >
    //           ...
    //         </h1>
    //       ) : (
    //         <PrimaryButton
    //           title="Approve"
    //           className="unblock-btn capitalize"
    //           onClick={async () => {
    //             // console.log(record.id);
    //             // setblockid(record.id);
    //             // setIsModalOpen(true);
    //           }}
    //         />
    //       )}
    //     </p>
    //   ),
    // },
  ];
  const urlParams = new URLSearchParams(window.location.search);
  const [clientData, setclientData] = useState([]);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [id, setid] = useState("");
  const [transaction, settransaction] = useState(false);
  const page = parseInt(urlParams.get("page")) || 1;
  const history = useHistory();
  // const { isLoading, data } = useQuery(["data", page], () => UserService.getallClients(page), {
  //   onSuccess: (data) => {
  //     setclientData(data?.clients);
  //     // let rooms = data.calls.results;
  //   },
  // });
  const handlePageChange = (page1) => {
    history.push(`?page=${page1}`);
  };

  return (
    <>
      <div
        className="dashboard-heading"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 className="darkGray font-light">Cards</h2>
        <PrimaryButton
          title="Add Card"
          onClick={() => {
            setaddCard(true);
            setcardDetail(false);
            setisModalOpen(true);
          }}
        />
      </div>
      {/* <div>
        <Select
          value={catType}
          style={{
            width: 200,
            padding: "0px",
          }}
          onChange={(value) => {
            setcatType(value);
          }}
          options={categories}
        />
      </div> */}
      <div className="dashboard-table sorting-table">
        <DashboardTable
          data={cardData}
          columns={columns}
          onRow={(data, rowIndex) => {
            return {
              onClick: () => {
                // history.push(`/clientDetail?id=${data.id}`);
                // console.log(data, rowIndex);
                setid(data);
                // setactive(false);
                setisModalOpen(true);
                setaddCard(false);
                setcardDetail(true);
              },
            };
          }}
        />
      </div>
      {clientData?.results?.length > 0 && (
        <DashboardPagination
          current={clientData?.page}
          total={clientData?.totalResults}
          pageSize={clientData?.limit}
          onChange={handlePageChange}
        />
      )}
      {isModalOpen && (
        <CardEdit
          // getProfile={getProfile}
          // userData={userData}
          getCardsData={getCardsData}
          message={message}
          setactive={setactive}
          active={active}
          id={id}
          addCard={addCard}
          setaddCard={setaddCard}
          catData={catData}
          cardDetail={cardDetail}
          setcardDetail={setcardDetail}
          isModalOpen={isModalOpen}
          categories={categories}
          transaction={transaction}
          settransaction={settransaction}
          setisModalOpen={setisModalOpen}
        />
      )}
    </>
  );
};

export default Cards;
