import axios from "./../libs/axios";

class AuthService {
  constructor() {}

  login(payload) {
    return axios.post(`auth/login`, payload);
  }
  register(payload) {
    return axios.post(`auth/register`, payload);
  }
  forgot(payload) {
    return axios.post(`auth/forgot-password`, payload);
  }
}

export default new AuthService();
