import React, { useEffect, useState } from "react";
import "./Blocked.scss";
import DashboardTable from "../../components/table/DashboardTable";
import DashboardPagination from "../../components/pagination/DashboardPagination";
import { sortArrow } from "../../assets/images/project-images";
import PrimaryButton from "../../components/button/PrimaryButton";
import axios from "../../libs/axios";
import { onError } from "../../libs/errorLib";
import { onSucess } from "../../libs/onSuccess";
import ModalWrapper from "../../components/modal/ModalWrapper";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import UserService from "../../services/user";

const Blocked = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [users, setusers] = useState([]);
  const [loader, setloader] = useState(false);
  const [blockid, setblockid] = useState("");
  const getAllUsers = async () => {
    try {
      const response = await axios.get(`/user/all`);
      console.log(response?.data?.data);
      let data = response?.data?.data.filter((item, i) => {
        return item.isActive === false;
      });
      setusers(data);
    } catch (error) {
      setusers([]);
      console.error("Error fetching data:", error?.message);
    }
  };
  const changeAccess = async (id) => {
    setloader(true);
    try {
      await axios.put(`/user/access/${blockid}`, { isActive: true });
      onSucess("Access changed successfully");
      getAllUsers();
    } catch (error) {
      onError("Something Went Wrong");
      console.error("Error fetching data:", error?.message);
    }
    setIsModalOpen(false);
    setloader(false);
  };

  useEffect(() => {
    getAllUsers();
  }, []);
  const columns = [
    {
      title: (
        <div className="cursor-pointer">
          Name
          {/* <img src={sortArrow} alt="sort-arrow" /> */}
        </div>
      ),
      dataIndex: "name",
      key: "name",
      render: (client_id) => <p className="font-bold darkBlue">{client_id}</p>,
    },
    {
      title: <div className="cursor-pointer">Email</div>,
      dataIndex: "email",
      key: "email",
      render: (name) => <p className="darkBlue">{name}</p>,
    },
    {
      title: <div className="cursor-pointer">PhoneNumber</div>,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (value) => <p className="darkBlue">{value ? value : "Null"}</p>,
    },
    // {
    //   title: <div className="cursor-pointer">Active</div>,
    //   dataIndex: " isActive",
    //   key: " isActive",
    //   render: (value) => <p className="darkBlue">{value === true ? "true" : "false"}</p>,
    // },
    {
      title: <div className="cursor-pointer">Verified</div>,
      dataIndex: " isVerified",
      key: " isVerified",
      render: (value) => <p className="darkBlue">{value === true ? "true" : "false"}</p>,
    },
    {
      title: <div className="cursor-pointer">Company</div>,
      dataIndex: "company",
      key: "company",
      render: (value) => <p className="darkBlue">{value ? value : "Null"}</p>,
    },
    {
      title: <div className="cursor-pointer">Role</div>,
      dataIndex: "role",
      key: "role",
      render: (value) => <p className="darkBlue">{value ? value : "Null"}</p>,
    },
    {
      title: "",
      dataIndex: "isActive",
      key: "isActive",
      render: (id, record) => (
        <PrimaryButton
          title="UnBlock"
          className="unblock-btn capitalize"
          onClick={async () => {
            setblockid(record.id);
            setIsModalOpen(true);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="dashboard-heading">
        <h2 className="darkGray font-light">Blocked Users</h2>
      </div>
      <div className="dashboard-table sorting-table blocked-table">
        <DashboardTable data={users} columns={columns} />
      </div>
      {isModalOpen && (
        <ModalWrapper
          isModalOpen={isModalOpen}
          setisModalOpen={setIsModalOpen}
          className="rate-per-minute-modal call-modal"
          Modalfooter={[
            <div className="modal-footer-buttons">
              <PrimaryButton
                isLoading={loader}
                disabled={loader}
                onClick={() => {
                  changeAccess();
                  setIsModalOpen(false);
                }}
                title="yes"
              />
              <PrimaryButton
                onClick={() => {
                  setIsModalOpen(false);
                }}
                title="cancel"
                className="secondary-button"
              />
            </div>,
          ]}
        >
          <h3 className="blue font-light text-3xl">Are you sure you want to unblock this user?</h3>
        </ModalWrapper>
      )}
    </>
  );
};

export default Blocked;
