import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputLabelField from "../../components/input-label-field/InputLabelField";
import SelectLabelField from "../../components/select-label-field/SelectLabelField";
import { yupResolver } from "@hookform/resolvers/yup";
import { userDetailsSchema } from "../../schemas/validation";
import InputLabelField1 from "../../components/input-label-field/inputLabelField1";
import PrimaryButton from "../../components/button/PrimaryButton";
import img0 from "../../assets/images/default.jpg";
import axios from "../../libs/axios";

export const selectLocation = ["lahore", "karachi", "multan"];
export const selectCurrency = ["USD", "PKR", "EUR", "IND"];

const CardDetail = ({
  setisModalOpen,
  settransaction,
  transaction,
  cardDetail,
  setcardDetail,
  id,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loader, setloader] = useState(false);
  const [cardData, setcardData] = useState({});
  const getCardDetail = async () => {
    setloader(true);
    try {
      const response = await axios.get(`/card/${id?.id}`);
      setcardData(response?.data?.data);
    } catch (error) {}
    setloader(false);
  };

  useEffect(() => {
    getCardDetail();
  }, [id]);

  // {
  // resolver: yupResolver(userDetailsSchema),
  // }

  //   const onSubmit = (data) => console.log(data);

  const onSubmit = async (data) => {
    // console.log(errors);
    // console.log(data);
    // try {
    //   const response = await axios.get("/card/all");
    //   console.log(response?.data);
    // } catch (error) {
    //   console.error("Error fetching data:", error?.message);
    // }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <>
        <div className="edit-profile-picture cursor-pointer mb-4" style={{ cursor: "pointer" }}>
          <img
            alt="not found"
            style={{
              width: "100%",
              height: "100px",
              cursor: "pointer",
              borderRadius: "10px",
            }}
            src={cardData?.photo ? cardData?.photo : img0}
          />
        </div>
        <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
          <InputLabelField
            register={register}
            label="Serial Number"
            type="text"
            value={cardData?.serialNumber}
            name="serialNumber"
            placeholder=""
            required={true}
            disabled={true}
          />
          <InputLabelField
            register={register}
            label="Balanace"
            type="text"
            value={cardData?.balance}
            disabled={true}
            name="manufacurar"
            placeholder=""
            required={true}
          />
        </div>
        <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
          <InputLabelField
            register={register}
            label="Expiry"
            value={cardData?.expiry}
            disabled={true}
            type="date"
            name="expiry"
            placeholder=""
            required={true}
          />
          <InputLabelField
            register={register}
            label="Owner Id"
            type="text"
            value={cardData?.ownerId ? cardData?.ownerId : "null"}
            disabled={true}
            name="category"
            placeholder=""
            required={true}
          />
        </div>
        <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
          <InputLabelField
            register={register}
            label="Type"
            value={cardData?.type}
            disabled={true}
            type="text"
            name="expiry"
            placeholder=""
            required={true}
          />
          <InputLabelField
            register={register}
            label="Selling Price"
            type="text"
            value={cardData?.sellingPrice ? cardData?.sellingPrice : "null"}
            disabled={true}
            name="category"
            placeholder=""
            required={true}
          />
        </div>
        <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
          <InputLabelField
            register={register}
            label="Geo Tag"
            value={cardData?.geotag ? cardData?.geotag : "null"}
            disabled={true}
            type="text"
            name="expiry"
            placeholder=""
            required={true}
          />
          <InputLabelField
            register={register}
            label="isActive"
            type="text"
            value={cardData?.isActive}
            disabled={true}
            name="category"
            placeholder=""
            required={true}
          />
        </div>
        <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
          <InputLabelField
            register={register}
            label="Is Sold"
            value={cardData?.isSold}
            disabled={true}
            type="text"
            name="isSold"
            placeholder=""
            required={true}
          />
          <InputLabelField
            register={register}
            label="Is Verified"
            type="text"
            value={cardData?.isVerified}
            disabled={true}
            name="category"
            placeholder=""
            required={true}
          />
        </div>
      </>
    </form>
  );
};

export default CardDetail;
