import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import DashboardTable from "../../components/table/DashboardTable";
import DashboardPagination from "../../components/pagination/DashboardPagination";
import { sortArrow } from "../../assets/images/project-images";
import UserService from "../../services/user";
import "./Categories.scss";
import axios from "../../libs/axios";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import PrimaryButton from "../../components/button/PrimaryButton";
import { Select } from "antd";
import config from "../../config";
import CategoryEdit from "./CategoryEdit";

const Categories = () => {
  const [cardData, setcardData] = useState([]);
  const [cardDetail, setcardDetail] = useState(false);
  const [id, setid] = useState("");
  const [addcategory, setaddcategory] = useState(false);
  const [catType, setcatType] = useState("Sports");
  const [catData, setcatData] = useState([]);
  const [message, setmessage] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const [clientData, setclientData] = useState([]);
  const [isModalOpen, setisModalOpen] = useState(false);
  const page = parseInt(urlParams.get("page")) || 1;
  const history = useHistory();

  const getAllCategories = async () => {
    try {
      const response = await axios.get(`/category/all`);
      setcatData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error?.message);
    }
  };

  useEffect(() => {}, [catType]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const columns = [
    {
      title: <div className="cursor-pointer">Category Name</div>,
      dataIndex: "categoryName",
      key: "categoryName",
      render: (name) => <p className="darkBlue">{name ? name : "NULL"}</p>,
    },

    {
      title: <div className="cursor-pointer">Is Active</div>,
      dataIndex: "isActive",
      key: "isActive",
      render: (name) => <p className="darkBlue">{name === true ? "true" : "false"}</p>,
    },
    {
      title: <div className="cursor-pointer">Created At</div>,
      dataIndex: "createdAt",
      key: "createdAt",
      render: (name) => <p className="darkBlue">{name.slice(0, 10)}</p>,
    },
    {
      title: <div className="cursor-pointer">Updated At</div>,
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (name) => <p className="darkBlue">{name.slice(0, 10)}</p>,
    },
    {
      title: "",
      dataIndex: "isActive",
      key: "isActive",
      render: (id, record) =>
        id === true ? (
          <PrimaryButton
            title="Disable"
            className="unblock-btn capitalize"
            onClick={async () => {
              setid(record);
              setisModalOpen(true);
              setmessage("disable");
              setaddcategory(false);
            }}
          />
        ) : (
          <PrimaryButton
            title="Enable"
            className="unblock-btn capitalize"
            onClick={async () => {
              setid(record);
              setisModalOpen(true);
              setmessage("enable");
              setaddcategory(false);
            }}
          />
        ),
    },
  ];

  const handlePageChange = (page1) => {
    history.push(`?page=${page1}`);
  };

  return (
    <>
      <div
        className="dashboard-heading"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 className="darkGray font-light">Categories</h2>
        <PrimaryButton
          title="Add Category"
          onClick={() => {
            setisModalOpen(true);
            setaddcategory(true);
            setid("");
          }}
        />
      </div>
      <div className="dashboard-table sorting-table">
        <DashboardTable
          data={catData}
          columns={columns}
          onRow={(data, rowIndex) => {
            return {
              onClick: () => {
                // history.push(`/clientDetail?id=${data.id}`);
                // console.log(data, rowIndex);
                setisModalOpen(true);
                setcardDetail(true);
              },
            };
          }}
        />
      </div>
      {clientData?.results?.length > 0 && (
        <DashboardPagination
          current={clientData?.page}
          total={clientData?.totalResults}
          pageSize={clientData?.limit}
          onChange={handlePageChange}
        />
      )}
      {isModalOpen && (
        <CategoryEdit
          id={id}
          getAllCategories={getAllCategories}
          addcategory={addcategory}
          message={message}
          isModalOpen={isModalOpen}
          setisModalOpen={setisModalOpen}
        />
      )}
    </>
  );
};

export default Categories;
