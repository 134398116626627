import React from "react";
import { Modal } from "antd";
import { CloseIcon } from "../../assets/images/project-svgs";
import "./ModalWrapper.scss";

const ModalWrapper = ({
  children,
  isModalOpen,
  setisModalOpen,
  Modalfooter,
  className,
  settransaction,
  setactive,
}) => {
  return (
    <>
      <Modal
        centered
        title="Basic Modal"
        open={isModalOpen}
        onOk={() => {
          setisModalOpen(false);
          settransaction && settransaction(false);
          setactive && setactive(false);
        }}
        onCancel={() => {
          setisModalOpen(false);
          setactive && setactive(false);
          settransaction && settransaction(false);
        }}
        footer={Modalfooter}
        closeIcon={<CloseIcon />}
        className={className}
      >
        {children}
      </Modal>
    </>
  );
};

export default ModalWrapper;
