import React, { useEffect, useState } from "react";
import "./DashboardCard.scss";
import { ClientUserCardArray, DashboardCardArray } from "./helper";
import {
  ActiveUsers,
  NewClients,
  NewUsers,
  Stats,
  TotalMinutes,
  UsedMinutes,
} from "../../assets/images/project-svgs";
import axios from "../../libs/axios";
import { useSelector } from "react-redux";

const DashboardCards = (props) => {
  console.log(props);
  const [dashboardContent, setdashboardContent] = useState([
    {
      cardIcon: <Stats />,
      cardText: "Total\nactive Users",
      cardValue: 0,
    },
    {
      cardIcon: <NewClients />,
      cardText: "Total\nBrands",
      cardValue: 0,
    },
    {
      cardIcon: <UsedMinutes />,
      cardText: "Total\nCards",
      cardValue: 0,
    },
    // {
    //   cardIcon: <ActiveUsers />,
    //   cardText: "Total\nactive users",
    //   cardValue: "0",
    // },
    // {
    //   cardIcon: <NewUsers />,
    //   cardText: "New users\nthis month",
    //   cardValue: "0 (0%)",
    // },
    // {
    //   cardIcon: <TotalMinutes />,
    //   cardText: "Total minutes\nused this month",
    //   cardValue: "0min (0%)",
    // },
  ]);

  const getInfo = async () => {
    try {
      let data = await axios.get("/dashboard/stats");
      console.log(data);
      let vl = data?.data?.data;
      setdashboardContent([
        {
          cardIcon: <Stats />,
          cardText: "Total\nactive Users",
          cardValue: vl?.user,
        },
        {
          cardIcon: <NewClients />,
          cardText: "Total\nBrands",
          cardValue: vl?.brand,
        },
        {
          cardIcon: <UsedMinutes />,
          cardText: "Total\nCards",
          cardValue: vl?.card,
        },
        // {
        //   cardIcon: <ActiveUsers />,
        //   cardText: "Total\nactive users",
        //   cardValue: data?.activeUsers,
        // },
        // {
        //   cardIcon: <NewUsers />,
        //   cardText: "New users\nthis month",
        //   cardValue: `${data?.currentMonthUsers} (${
        //     data?.userIncreasePercentage ? data?.userIncreasePercentage : "0"
        //   }%)`,
        // },
        // {
        //   cardIcon: <TotalMinutes />,
        //   cardText: "Total minutes\nused this month",
        //   cardValue: "0min (0%)",
        // },
      ]);
    } catch (error) {}
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      {props?.dashboardCards &&
        dashboardContent.map((item, index) => (
          <div className="dashboard-card" key={index}>
            <div>
              {item.cardIcon}
              <h4 className="blue font-medium text-sm sm:text-xl pt-4 pb-6">{item.cardText}:</h4>
            </div>
            <p className="darkBlue font-light text-base sm:text-3xl z-10">{item.cardValue}</p>
            <div className="card-icon z-0">{item.cardIcon}</div>
          </div>
        ))}
    </>
  );
};

export default DashboardCards;
