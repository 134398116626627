import CardWrapper from './../../components/card/CardWrapper';

const NotFound = () => {
  return (
    <div className="dashboard-login">
      <CardWrapper>
        <div className="login-card-content">
          <h3 className="font-semibold darkGray text-3xl mb-2">404</h3>
          <h3 className="font-semibold darkGray text-3xl mb-5">Page Not Found</h3>
          <p className='darkBlue text-lg font-normal'>The requested page does not exist.</p>
        </div>
      </CardWrapper>
    </div>
  );
};
export default NotFound;

