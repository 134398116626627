import React, { useEffect, useState } from "react";
import ModalWrapper from "../../components/modal/ModalWrapper";
import PrimaryButton from "../../components/button/PrimaryButton";
import axios from "../../libs/axios";
import "./Categories.scss";
// import ProfileForm from "./ProfileForm";
import img from "../../assets/images/profilepic.jpg";
import img0 from "../../assets/images/default.jpg";
import { useDispatch, useSelector } from "react-redux";
import { onSucess } from "../../libs/onSuccess";
import { onError } from "../../libs/errorLib";
import { setUser } from "../../store/Auth/authSlice";
import config from "../../config";
import CategoryForm from "./CategoryForm";

const CategoryEdit = ({
  id,
  isModalOpen,
  message,
  setisModalOpen,
  addcategory,
  getAllCategories,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const [loader, setloader] = useState(false);

  const changeAccess = async () => {
    setloader(true);
    try {
      await axios.put(`/category/access/${id?.id}`, { isActive: !id?.isActive });
      onSucess("Access changed successfully");
      getAllCategories();
    } catch (error) {
      onError("Something Went Wrong");
      console.error("Error fetching data:", error?.message);
    }
    setisModalOpen(false);
    setloader(false);
  };
  console.log(id);

  return (
    <ModalWrapper
      isModalOpen={isModalOpen}
      className="account-edit-modal"
      setisModalOpen={setisModalOpen}
      Modalfooter={[
        <>
          {" "}
          {id && (
            <div
              className="modal-footer-buttons"
              style={{ marginTop: "60px", display: "flex", justifyContent: "center" }}
            >
              <PrimaryButton
                isLoading={loader}
                disabled={loader}
                onClick={() => {
                  changeAccess();
                }}
                title="yes"
              />
              <PrimaryButton
                onClick={() => {
                  setisModalOpen(false);
                }}
                title="cancel"
                className="secondary-button"
              />
            </div>
          )}
        </>,
      ]}
    >
      {/* <div className="account-edit-modal-content"> */}
      {id && (
        <div style={{ width: "70%", textAlign: "center", marginInline: "auto" }}>
          <h3 className="blue font-light text-3xl" style={{}}>
            Are you sure you want to {message} this category?
          </h3>
        </div>
      )}
      {addcategory && (
        <>
          <h3 className="blue font-light mb-4" style={{ fontSize: "29px", fontWeight: 400 }}>
            Add Category
          </h3>
          <CategoryForm setisModalOpen={setisModalOpen} getAllCategories={getAllCategories} />
        </>
      )}
    </ModalWrapper>
  );
};

export default CategoryEdit;
