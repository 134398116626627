import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputLabelField from "../../components/input-label-field/InputLabelField";
import SelectLabelField from "../../components/select-label-field/SelectLabelField";
import { yupResolver } from "@hookform/resolvers/yup";
import { userDetailsSchema } from "../../schemas/validation";
import InputLabelField1 from "../../components/input-label-field/inputLabelField1";
import PrimaryButton from "../../components/button/PrimaryButton";
import axios from "../../libs/axios";
import { onSucess } from "../../libs/onSuccess";
import { onError } from "../../libs/errorLib";

const CategoryForm = ({ setisModalOpen, getAllCategories }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loader, setloader] = useState(false);

  const onSubmit = async (data) => {
    setloader(true);
    try {
      await axios.post("/category/add", data);
      onSucess("Category added successfully");
      setisModalOpen(false);
      getAllCategories();
    } catch (error) {
      onError("Not added");
    }
    setloader(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <>
        <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
          <InputLabelField
            register={register}
            label="Category Name"
            type="text"
            name="name"
            placeholder="Enter Category Name"
            required={true}
          />
        </div>
        {Object.keys(errors).length > 0 && (
          <p style={{ textAlign: "center", color: "red", margin: "10px" }}>
            Category Name is required
          </p>
        )}

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
          <PrimaryButton
            isLoading={loader}
            disabled={loader}
            onClick={handleSubmit}
            type="submit"
            title="Save"
          />
          <PrimaryButton
            onClick={() => {
              setisModalOpen(false);
              setloader(false);
            }}
            title="cancel"
            className="secondary-button"
          />{" "}
        </div>
      </>
    </form>
  );
};

export default CategoryForm;
