import axios from "./../libs/axios";

class UserService {
  constructor() {}

  checkout(payload) {
    return axios.post(`users/checkout/sessions`, payload);
  }
  calls(page) {
    return axios.get(`users/calls?page=${page}`);
  }
  getallClients(page) {
    return axios.get(`users/getallclients?page=${page}`);
  }
  getallUsers(page) {
    return axios.get(`users/getallusers?page=${page}`);
  }

  getallblockers(page) {
    return axios.get(`users/getblockusers?page=${page}`);
  }

  payments() {
    return axios.get(`users/payments`);
  }
  getUsers(id) {
    return axios.get(`users/${id}`);
  }
  markFavorite(userId) {
    console.log(userId);
    return axios.get(`users/favorite/${userId}`);
  }
}

export default new UserService();
