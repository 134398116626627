import React, { useState } from "react";

import Sidebar from "../components/sidebar/Sidebar";
import { hamburger } from "../assets/images/project-images";
import { DashboardLogo } from "../assets/images/project-svgs";

const Main = ({ children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="dashboard-layout">
      <Sidebar open={open} setOpen={setOpen} />
      <div className="dashboard-content">
        <div className="show-tab">
          <img className="cursor-pointer" src={hamburger} alt="hamburger" onClick={() => setOpen(true)} />
          {<DashboardLogo />}
        </div>
        <div className="dashboard-components">{children}</div>
      </div>
    </div>
  );
};

export default Main;
