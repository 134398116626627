import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputLabelField from "../../components/input-label-field/InputLabelField";
import SelectLabelField from "../../components/select-label-field/SelectLabelField";
import { yupResolver } from "@hookform/resolvers/yup";
import { userDetailsSchema } from "../../schemas/validation";
import InputLabelField1 from "../../components/input-label-field/inputLabelField1";
import PrimaryButton from "../../components/button/PrimaryButton";
import axios from "../../libs/axios";
import { Select } from "antd";
import { onSucess } from "../../libs/onSuccess";
import { onError } from "../../libs/errorLib";

const CardForm = ({
  setisModalOpen,
  settransaction,
  transaction,
  image,
  categories,
  catData,
  getCardsData,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loader, setloader] = useState(false);
  const [catType, setcatType] = useState(catData[0].value);
  const [brandData, setbrandData] = useState([]);
  const [brandType, setbrandType] = useState();

  const onSubmit = async (data) => {
    setloader(true);
    try {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("image", image);
      formData.append("category", catType);
      formData.append("brandId", brandType);
      const response = await axios.post("/card/add", formData);
      onSucess(response?.data?.response?.DESCRIPTION);
      setisModalOpen(false);
      getCardsData();
    } catch (error) {
      onError("Card Not added");
      console.error("Error fetching data:", error?.message);
    }
    setloader(false);
  };

  const getBrandsData = async () => {
    try {
      const response = await axios.get(`/brand/all`);
      let data0 = response?.data?.data.map((item, i) => {
        return { value: item?.id, label: item?.name };
      });
      setbrandData(data0);
      setbrandType(data0[0]?.value);
    } catch (error) {
      setbrandData([]);
      console.error("Error fetching data:", error?.message);
    }
  };

  useEffect(() => {
    getBrandsData();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {transaction ? (
        <>
          <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
            <InputLabelField
              register={register}
              label="First Name"
              type="text"
              name="firstName"
              placeholder="Andrew"
              required={true}
            />
            <InputLabelField
              register={register}
              label="Last Name"
              type="text"
              name="lastName"
              placeholder="Armstrong"
              required={true}
            />
          </div>
          <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
            <InputLabelField
              register={register}
              label="Email"
              type="email"
              name="email"
              placeholder="armstrong_andrew@gmail.com"
              required={true}
            />
            <InputLabelField
              register={register}
              label="Phone"
              type="phone"
              name="phone"
              placeholder="+12-123-1234567 "
              required={true}
            />
          </div>
          <div className="flex gap-4 mb-4">
            <InputLabelField
              register={register}
              label="Address"
              type="text"
              name="address"
              placeholder="Lorem ipsum dolor sit amet, consectetur"
              required={true}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
            <PrimaryButton isLoading={loader} onClick={handleSubmit} type="submit" title="Save" />
            <PrimaryButton
              onClick={() => {
                setisModalOpen(false);
                setloader(false);
                settransaction(false);
              }}
              title="cancel"
              className="secondary-button"
            />{" "}
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
            <InputLabelField
              register={register}
              label="Serial Number"
              type="text"
              name="serialNumber"
              placeholder="Enter Serial Number"
              required={true}
            />
            <div>
              <p style={{ fontWeight: 600, color: "#5D5D5D", marginBottom: "7px" }}>Brand Name</p>
              <Select
                value={brandType}
                style={{
                  width: 228,
                  padding: "0px",
                }}
                onChange={(value) => {
                  setbrandType(value);
                }}
                options={brandData}
              />
            </div>
          </div>
          <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
            <InputLabelField
              register={register}
              label="Expiry"
              type="date"
              name="expiry"
              placeholder="Enter Expiry Date"
              required={true}
            />
            {/* <InputLabelField
              register={register}
              label="Category"
              type="text"
              name="category"
              placeholder="Enter Category"
              required={true}
            /> */}
            <div>
              <p style={{ fontWeight: 600, color: "#5D5D5D", marginBottom: "7px" }}>Category</p>
              <Select
                value={catType}
                style={{
                  width: 228,
                  padding: "0px",
                }}
                onChange={(value) => {
                  setcatType(value);
                }}
                options={catData}
              />
            </div>
          </div>
          <div className="flex gap-4 mb-4">
            <InputLabelField
              register={register}
              label="Type"
              type="text"
              name="type"
              placeholder=""
              required={true}
            />
            <InputLabelField
              register={register}
              label="Balance"
              type="text"
              name="balance"
              placeholder=""
              required={true}
            />
          </div>
          {Object?.keys(errors).length > 0 && (
            <p style={{ marginBottom: "13px", color: "red", textAlign: "center" }}>
              Please Fill Out the field
            </p>
          )}

          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
            <PrimaryButton isLoading={loader} onClick={handleSubmit} type="submit" title="Save" />
            <PrimaryButton
              onClick={() => {
                setisModalOpen(false);
                setloader(false);
                settransaction(false);
              }}
              title="cancel"
              className="secondary-button"
            />{" "}
          </div>
        </>
      )}
    </form>
  );
};

export default CardForm;
