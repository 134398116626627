import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import UnAuthenticated from "./UnAuthenticated";

import { routes, unAuthenticated } from "./routes";
import NotFound from "../pages/notfound/NotFound";
import Unauthorized from "../pages/unauthorized/UnAuthorized";
import Login from "../pages/auth/Login";
import ErrorBoundary from "../ErrorBoundary";
import { useSelector } from "react-redux";
import Loader from "../components/loader/loader";
import Layout from "../layouts/main";
import DetailPage from "../pages/users/DetailPage";

const Routes = () => {
  const { isLoggedIn, user } = useSelector(({ auth }) => auth);
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Switch>
            {routes.map((route, index) => (
              <ProtectedRoute
                key={index}
                path={route.path}
                component={route.component}
                roles={route.roles}
                exact={route.exact}
                layout={route.layout}
              />
            ))}
            {unAuthenticated.map((route, index) => (
              <UnAuthenticated
                key={index}
                path={route.path}
                component={route.component}
                layout={route.layout}
                exact={true}
              />
            ))}
            <ProtectedRoute
              key="3"
              roles={["admin"]}
              exact={true}
              path="/detail"
              component={DetailPage}
              layout={Layout}
            />

            <Route path="/unauthorized" component={Unauthorized} />
            <Route path="/not-found" component={NotFound} />
            <Redirect to="/not-found" />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
};
export default Routes;
