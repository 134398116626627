import React from 'react'
import { Pagination } from 'antd'
import './DashboardPagination.scss'

const DashboardPagination = ({ current, total, pageSize, onChange }) => {
  return (
    <div className="dashboard-pagination">
      <Pagination
        current={current}
        total={total}
        pageSize={pageSize}
        onChange={onChange}
      />
    </div>
  );
};

export default DashboardPagination
