import React from "react";
import DashboardCards from "../../components/dashboard-card/DashboardCard";
import './DashboardPage.scss'
import { useState } from "react";

const AdminDashboard = () => {
  const [dashboardCards] = useState(true)
  return (
    <>
      <div className="dashboard-heading">
        <h2 className="darkGray font-light">Stats</h2>
      </div>
      <div className="admin-dashboard">
        <DashboardCards dashboardCards={dashboardCards}/>
      </div>
    </>
  );
};

export default AdminDashboard;