import React from "react";
import "./InputLabelField.scss";

const InputLabelField = ({
  label,
  type,
  value,
  register,
  placeholder,
  name,
  min,
  handleChange,
  field,
  required,
  disabled,
}) => {
  return (
    <div className="label-input w-full">
      <label htmlFor={name} className="darkGray font-semibold">
        {label}
      </label>
      <input
        className="darkBlue font-normal text-sm"
        min={min}
        disabled={disabled}
        type={type}
        value={value}
        placeholder={placeholder}
        name={name || ""}
        {...register(name, { required: required })}
      />
    </div>
  );
};

export default InputLabelField;
