import React from "react";
import "./button.scss";
import ButtonLoader from "../loader/ButtonLoader";

const PrimaryButton = ({
  type,
  title,
  onClick,
  disabled,
  className,
  isLoading,
}) => {
  return (
    <>
      <button
        className={`primary-button white uppercase text-lg font-bold ${
          className && className
        } ${disabled ? 'disabled' : ''} `}
        onClick={onClick}
        type={type || "button"}
        disabled={disabled}
        // disabled={isLoading}
        style={{ pointerEvents: isLoading ? "none" : "auto" }}
      >
        {isLoading ? <ButtonLoader/> : title}
      </button>
    </>
  );
};

export default PrimaryButton;
