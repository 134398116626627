import axios from "./../libs/axios";

class StripeService {
  constructor() {}

  connect(payload) {
    return axios.post(`stripe/account`, payload);
  }
  bank() {
    return axios.get(`stripe/account/bank`);
  }
  intent() {
    return axios.get(`stripe/intent`);
  }
  paymentMethods() {
    return axios.get(`stripe/payment/methods`);
  }
}

export default new StripeService();
