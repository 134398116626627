export function formatPhoneNumber(phone) {
  if (!phone) return "";
  const phoneNumberString = phone.replace(/\D/g, "").slice(-10);
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return phone;
}
//Currency Formatter
export function formatCurrency(number) {
  number = number / 100;
  return (
    "$" +
    parseFloat(number)
      .toFixed(2)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
}
export function formatPercentage(number) {
  return parseFloat(number) + "%";
}
export const hasPermission = (routeRoles, userRole) => {
  return routeRoles && routeRoles.includes(userRole);
};
export function formatSeconds(duration) {
  const minutes = duration / 60;
  return parseFloat(minutes).toFixed(2) + "min";
}
export function formatDate(timestamp) {
  const date = new Date(timestamp);

  // Format the date components
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
  const day = ("0" + date.getDate()).slice(-2);

  // Combine the formatted components into a readable date string
  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}
export const convertToStripeCreated = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert the timestamp to milliseconds
  const formattedDate = date.toLocaleString(); // Convert the date to a human-readable format
  return formattedDate;
};