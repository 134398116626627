import React, { useEffect, useState } from "react";
import CardWrapper from "../../components/card/CardWrapper";
import {
  backButton,
  phone,
  profileEmailIcon,
  profilePicture,
  user,
} from "../../assets/images/project-images";
import pic1 from "../../assets/images/profilepic.jpg";
import DashboardCards from "./../../components/dashboard-card/DashboardCard";
import PrimaryButton from "../../components/button/PrimaryButton";

import "./Users.scss";
import axios from "../../libs/axios";
import { onSucess } from "../../libs/onSuccess";
import { onError } from "../../libs/errorLib";
import ModalWrapper from "../../components/modal/ModalWrapper";
import config from "../../config";

const DetailPage = ({ data }) => {
  const [userData, setuserData] = useState({});
  const [date, setdate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const [message, setmessage] = useState("");

  const [id, setid] = useState("");
  const getProfile = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const status = urlParams.get("id");
      setid(status);
      const data = await axios.get(`/users/${status}`);
      const date = new Date(data?.createdAt);

      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      const formattedDate = `${day} ${month} ${year}`;
      setdate(formattedDate);
      setuserData(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProfile();
  }, []);

  const removeUser = async () => {
    try {
      await axios.patch(`/users/removeUser/${id}`);
      onSucess("User Remove Successfully");
    } catch (error) {
      onError("Somthing Went Wrong");
    }
    setloading(false);
  };

  const BlockUser = async () => {
    try {
      await axios.patch(`/users/blockuser/${id}`);
      onSucess("User Block Successfully");
    } catch (error) {
      onError("Somthing Went Wrong");
    }
    setloading(false);
  };

  const RemoveBlockUser = async () => {
    try {
      await axios.patch(`/users/removeandblockUser/${id}`);
      onSucess("User Block and Remove Successfully");
    } catch (error) {
      onError("Somthing Went Wrong");
    }
    setloading(false);
  };

  return (
    <>
      <div className="dashboard-heading flex gap-5 items-center">
        <button>
          <img src={backButton} alt="back-button" />
        </button>
        <h2 className="darkGray font-light">Users</h2>
      </div>
      <div className="account-cards admin-user-card">
        <CardWrapper className="account-card-info">
          <div className="profile-picture">
            <img
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={userData?.image ? config.IMG_BASE_URL + userData?.image : pic1}
              alt="profile-img"
            />
          </div>
          <div className="account-detail mt-9">
            <ul>
              <li>
                <p className="blue text-base font-normal">
                  <img src={user} alt="user-img" />
                  Name:
                </p>
                <span className="darkBlue text-base font-semibold">
                  {" "}
                  {userData?.first_name ? userData?.first_name : "Not Provided"}
                </span>
              </li>
              <li>
                <p className="blue text-base font-normal">
                  <img src={user} alt="user-img" />
                  Last Name:
                </p>
                <span className="darkBlue text-base font-semibold">
                  {userData?.last_name ? userData?.last_name : "Not Provided"}
                </span>
              </li>
              <li>
                <p className="blue text-base font-normal">
                  <img src={profileEmailIcon} alt="email" />
                  Member since:
                </p>
                <span className="darkBlue text-base font-semibold">{date}</span>
              </li>
              <li>
                <p className="blue text-base font-normal">
                  <img src={phone} alt="phone" />
                  Client ID:
                </p>
                <span className="darkBlue text-base font-semibold">
                  {userData?.id ? userData?.id : "Not Provided"}
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <p className="blue text-base font-normal">
                  <img src={user} alt="user-img" />
                  Address:
                </p>
                <span className="darkBlue text-base font-semibold">
                  {userData?.address ? userData?.address : "Not Provided"}
                </span>
              </li>
              <li>
                <p className="blue text-base font-normal">
                  <img src={user} alt="user-img" />
                  Country:
                </p>
                <span className="darkBlue text-base font-semibold">
                  {userData?.country ? userData?.country : "Not Provided"}
                </span>
              </li>
              <li>
                <p className="blue text-base font-normal">
                  <img src={profileEmailIcon} alt="email" />
                  Phone number:
                </p>
                <span className="darkBlue text-base font-semibold">
                  {userData?.phone ? userData?.phone : "Not Provided"}
                </span>
              </li>
              <li>
                <p className="blue text-base font-normal">
                  <img src={phone} alt="phone" />
                  Status:
                </p>
                <span className="darkBlue text-base font-semibold">Active</span>
              </li>
            </ul>
          </div>
        </CardWrapper>
      </div>
      <div className="admin-dashboard detail-page-cards">
        <DashboardCards role={"user"} />
      </div>
      <div className="remove-block-buttons">
        <PrimaryButton
          title="Remove User"
          onClick={() => {
            setmessage("remove");
            setIsModalOpen(true);
          }}
        />
        <PrimaryButton
          title="Block User"
          onClick={() => {
            setmessage("block");
            setIsModalOpen(true);
          }}
        />
        <PrimaryButton
          title="Remove and block"
          className="secondary-button"
          onClick={() => {
            setmessage("remove and block");
            setIsModalOpen(true);
          }}
        />
      </div>
      {isModalOpen && (
        <ModalWrapper
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          className="rate-per-minute-modal call-modal"
          Modalfooter={[
            <div className="modal-footer-buttons">
              <PrimaryButton
                onClick={() => {
                  setloading(true);
                  if (message === "remove") {
                    removeUser();
                  } else if (message === "block") {
                    BlockUser();
                  } else {
                    RemoveBlockUser();
                  }
                  setIsModalOpen(false);
                }}
                title="yes"
              />
              <PrimaryButton
                onClick={() => {
                  setIsModalOpen(false);
                }}
                title="cancel"
                className="secondary-button"
              />
            </div>,
          ]}
        >
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyontent: "center",
              padding: "34px",
              alignItems: "center",
            }}
          > */}
          <h5 className="font-semibold text-lg darkBlue mb-3">Accept</h5>
          <h3 className="blue font-light text-3xl">
            Are you sure you want to {message} this user ?
          </h3>
          {/* </div> */}
        </ModalWrapper>
      )}
    </>
  );
};

export default DetailPage;
