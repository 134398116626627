import React, { useState } from "react";
import DashboardTable from "../../components/table/DashboardTable";
import DashboardPagination from "../../components/pagination/DashboardPagination";
import { useQuery } from "react-query";
import UserService from "../../services/user";
import { convertToStripeCreated, formatCurrency } from "../../libs/utils";
import Loader from "../../components/loader/loader";
const data = [
  {
    key: Math.random(),
    date: "12/05/22",
    amount: "320 usd",
    status: "Completed",
  },
  {
    key: Math.random(),
    date: "12/05/22",
    amount: "320 usd",
    status: "Completed",
  },
  {
    key: Math.random(),
    date: "12/05/22",
    amount: "320 usd",
    status: "Completed",
  },
  {
    key: Math.random(),
    date: "12/05/22",
    amount: "320 usd",
    status: "Completed",
  },
];

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (date) => <p className="font-bold darkBlue">{date}</p>,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   key: "status",
  //   render: (status) => <p className="font-bold blue">{status}</p>,
  // },
];

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const { isLoading, data } = useQuery(
    "payments",
    () => UserService.payments(),
    {
      onSuccess: (data) => {
        let py = data.payments.data;
        if (py) {
          const ca = py.map((payment, key) => {
            return {
              key,
              date: convertToStripeCreated(payment.created),
              amount: formatCurrency(payment.amount),
            };
          });
          setPayments(ca);
        }
      },
    }
  );
  return (
    <>
      <div className="dashboard-heading">
        <h2 className="darkGray font-light">My Payments</h2>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        data && (
          <div className="dashboard-table">
            <DashboardTable data={payments} columns={columns} />
          </div>
        )
      )}

      {/* <DashboardPagination defaultCurrent={1} total={100} /> */}
    </>
  );
};

export default Payments;
