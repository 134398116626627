import React, { lazy } from "react";
import Layout from "../layouts/main";
import AuthLayout from "../layouts/auth";
import {
  AccountIcon,
  BlockedIcon,
  CallIcon,
  ClientsIcon,
  PaymentIcon,
  ProfilesIcon,
  StatsIcon,
  SubscriptionIcon,
} from "../assets/images/project-svgs";

// const Brands = lazy(() => import("../pages/Brands/Brands"));
// const Categories = lazy(() => import("../pages/Categories/Categories"));

// const Users = lazy(() => import("../pages/users/Users"));
// const Blocked = lazy(() => import("../pages/blocked/Blocked"));
// const Cards = lazy(() => import("../pages/cards/cards"));
// const Payments = lazy(() => import("../pages/payments/Payments"));
// const Login = lazy(() => import("../pages/auth/Login"));

// const DashboardPage = lazy(() => import("../pages/dashboard/user/Dashboard"));
// const AdminDashboard = lazy(() => import("../pages/dashboard/AdminDashboard"));
// const Account = lazy(() => import("../pages/account/Account"));
// const Profiles = lazy(() => import("../pages/profiles/Profiles"));
// const SignUp = lazy(() => import("../pages/auth/Signup"));
import Brands from "../pages/Brands/Brands";
import Categories from "../pages/Categories/Categories";

import Users from "../pages/users/Users";
import Blocked from "../pages/blocked/Blocked";
import Cards from "../pages/cards/cards";
import Payments from "../pages/payments/Payments";
import Login from "../pages/auth/Login";

import DashboardPage from "../pages/dashboard/user/Dashboard";
import AdminDashboard from "../pages/dashboard/AdminDashboard";
import Account from "../pages/account/Account";
import Profiles from "../pages/profiles/Profiles";
import SignUp from "../pages/auth/Signup";
export const routes = [
  {
    path: "/dashboard",
    component: AdminDashboard,
    roles: ["admin"],
    layout: Layout,
    title: "Stats",
    icon: <StatsIcon />,
  },
  {
    title: "Cards",
    icon: <ClientsIcon />,
    path: "/cards",
    component: function () {
      // return <Clients />;
      return <Cards />;
    },
    roles: ["admin"],
    layout: Layout,
  },
  {
    title: "Brands",
    icon: <PaymentIcon />,
    path: "/brands",
    component: function () {
      // return <Clients />;
      return <Brands />;
    },
    roles: ["admin"],
    layout: Layout,
  },
  {
    title: "Categories",
    icon: <SubscriptionIcon />,
    path: "/categories",
    component: function () {
      // return <Clients />;
      return <Categories />;
    },
    roles: ["admin"],
    layout: Layout,
  },
  {
    title: "Users",
    icon: <ProfilesIcon />,
    path: "/users",
    component: function () {
      return <Users />;
    },
    roles: ["admin"],
    layout: Layout,
  },
  {
    title: "Blocked",
    icon: <BlockedIcon />,
    path: "/blocked",
    component: function () {
      return <Blocked />;
    },
    roles: ["admin"],
    layout: Layout,
  },
];
export const unAuthenticated = [
  {
    path: "/",
    component: Login,
    layout: AuthLayout,
  },
  {
    path: "/sign-up",
    component: SignUp,
    layout: AuthLayout,
  },
];
