import React, { useEffect, useState } from "react";
import ModalWrapper from "../../components/modal/ModalWrapper";
import PrimaryButton from "../../components/button/PrimaryButton";
import axios from "../../libs/axios";
// import ProfileForm from "./ProfileForm";
import img from "../../assets/images/profilepic.jpg";
import "../users/Users.scss";
import img0 from "../../assets/images/default.jpg";
import { useDispatch, useSelector } from "react-redux";
import { onSucess } from "../../libs/onSuccess";
import { onError } from "../../libs/errorLib";
import { setUser } from "../../store/Auth/authSlice";
import config from "../../config";
import BrandForm from "./BrandForm";

const BrandEdit = ({ id, isModalOpen, message, setisModalOpen, addbrand, getBrandsData }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const [loader, setloader] = useState(false);
  const [image, setimage] = useState(null);

  const onFileChange = (event) => {
    setimage(event.target.files[0]);
  };

  const handleSubmit = async () => {
    setloader(true);
  };
  const changeAccess = async () => {
    setloader(true);
    try {
      await axios.put(`/brand/access/${id?.id}`, { isActive: !id?.isActive });
      onSucess("Access changed successfully");
      getBrandsData();
    } catch (error) {
      onError("Something Went Wrong");
      console.error("Error fetching data:", error?.message);
    }
    setisModalOpen(false);
    setloader(false);
  };

  return (
    <ModalWrapper
      isModalOpen={isModalOpen}
      className="account-edit-modal"
      setisModalOpen={setisModalOpen}
      Modalfooter={[
        <>
          {id && (
            <div
              className="modal-footer-buttons"
              style={{ marginTop: "60px", display: "flex", justifyContent: "center" }}
            >
              <PrimaryButton
                isLoading={loader}
                disabled={loader}
                onClick={() => {
                  changeAccess();
                  // setisModalOpen(false);
                }}
                title="yes"
              />
              <PrimaryButton
                onClick={() => {
                  setisModalOpen(false);
                }}
                title="cancel"
                className="secondary-button"
              />
            </div>
          )}
        </>,
      ]}
    >
      {/* <div className="account-edit-modal-content"> */}
      {id && (
        <div style={{ width: "70%", textAlign: "center", marginInline: "auto" }}>
          <h3 className="blue font-light text-3xl" style={{}}>
            Are you sure you want to {message} this Brand?
          </h3>
        </div>
      )}
      {addbrand && (
        <>
          {" "}
          <h3 className="blue font-light mb-4" style={{ fontSize: "29px", fontWeight: 400 }}>
            Add Brand
          </h3>
          <div className="edit-profile-picture cursor-pointer mb-4" style={{ cursor: "pointer" }}>
            <input
              style={{ display: "none" }}
              accept="image/*"
              fullWidth={true}
              id="contained-button-file"
              onChange={onFileChange}
              type="file"
              name="image"
            />
            <label htmlFor="contained-button-file">
              <div>
                <div>
                  <img
                    alt="not found"
                    style={{
                      width: "22%",
                      height: "100px",
                      cursor: "pointer",
                      borderRadiuss: "10px",
                    }}
                    src={image ? URL.createObjectURL(image) : img0}
                  />
                </div>
              </div>
            </label>
          </div>
          <BrandForm setisModalOpen={setisModalOpen} getBrandsData={getBrandsData} image={image} />
        </>
      )}
    </ModalWrapper>
  );
};

export default BrandEdit;
