import React, { useEffect, useMemo, useState } from "react";
import ModalWrapper from "../../../components/modal/ModalWrapper";
import PrimaryButton from "../../../components/button/PrimaryButton";
import { useSelector } from "react-redux";
import { PaymentElement } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useQuery } from "react-query";
import Stripe from "../../../services/stripe";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
const stripePromise = loadStripe("pk_test_qCmIoNbkNqQ91nUh4GMbUdBc00iBtQdPHn");
const Form = ({ isModalOpen, setIsModalOpen }) => {
  const { user } = useSelector(({ auth }) => auth);
  const { data, isLoading, isError, error, refetch } = useQuery(
    "intent",
    Stripe.intent,
    {
      enabled: false,
    }
  );
  useEffect(() => {
    refetch();
  }, []);
  const clientSecret = useMemo(() => {
    return data?.intent.client_secret;
  }, [data]);
  const options = {
    // passing the SetupIntent's client secret
    clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <ModalWrapper
      isModalOpen={isModalOpen}
      className="account-edit-modal"
      setIsModalOpen={setIsModalOpen}
      Modalfooter={[
        // <div className='modal-footer-buttons mt-7'>
        //   <PrimaryButton
        //     onClick={() => setIsModalOpen(false)}
        //     title="cancel"
        //     className="secondary-button"
        //   />
        // </div>
      ]}
    >
      <div className="account-edit-modal-content">
        <h3 className="blue font-light mb-4">Payment Details</h3>

        {data && clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <PaymentForm setIsModalOpen={setIsModalOpen}/>
          </Elements>
        )}
      </div>
    </ModalWrapper>
  );
};

export default Form;
