import React, { useEffect, useMemo, useState } from "react";
import ModalWrapper from "../../../components/modal/ModalWrapper";
import PrimaryButton from "../../../components/button/PrimaryButton";
import { useSelector } from "react-redux";
import { PaymentElement } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useQuery } from "react-query";
import Stripe from "../../../services/stripe";
import { useStripe, useElements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe("pk_test_qCmIoNbkNqQ91nUh4GMbUdBc00iBtQdPHn");
const PaymentForm = ({ setIsModalOpen }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      //   confirmParams: {
      //     redirect: "if_required",
      //     //   return_url: "http://localhost:3000/my-accounts",
      //   },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setLoading(false);
      setErrorMessage(error.message);
    } else {
      setLoading(false);
      window.location.reload();
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {errorMessage && <div>{errorMessage}</div>}
      <div className="modal-footer-buttons mt-7">
        <PrimaryButton isLoading={loading} type="submit" title="Submit" />
        <PrimaryButton
          onClick={() => setIsModalOpen(false)}
          title="cancel"
          className="secondary-button"
        />
      </div>
    </form>
  );
};
export default PaymentForm;
