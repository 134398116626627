import React from "react";
import ModalWrapper from "../../components/modal/ModalWrapper";
import PrimaryButton from "../../components/button/PrimaryButton";
import { forgotSchema } from "../../schemas/validation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthService from "../../services/auth";
import { useMutation, useQueryClient } from "react-query";
import { Alert } from "antd";
import "./Login.scss";

const ForgotPassword = ({ isModalOpen, setIsModalOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotSchema),
  });
  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    AuthService.forgot,
    {}
  );
  const onSubmit = async (data) => {
    mutate(data);
  };
  return (
    <ModalWrapper
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      className="forgot-password-modal"
      Modalfooter={[
        <div className="modal-footer-buttons">
          {/* <PrimaryButton onClick={() => setIsModalOpen(false)} title="sent" /> */}
          {/* <PrimaryButton
            onClick={() => setIsModalOpen(false)}
            title="cancel"
            className="secondary-button"
          /> */}
        </div>,
      ]}
    >
      <div className="modal-content">
        <h3 className="blue font-light text-3xl mb-4">
          Enter the email address linked to your account
        </h3>
        {isError && <Alert message={error.message} type="error" showIcon />}
        {isSuccess && (
          <Alert
            message="Link sent to your registered email"
            type="success"
            showIcon
          />
        )}
        <p className="darkBlue font-semibold text-lg mb-10">
          You will receive a link to reset your password.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="login-input">
            <input
              {...register("email")}
              type="email"
              placeholder="Email"
              // autocomplete="off"
            />
            <p className="error">{errors.email?.message}</p>
          </div>
          <div className="login-button modal-footer-buttons">
            <PrimaryButton type="submit" title="Send" isLoading={isLoading} />
            <PrimaryButton
              className="secondary-button"
              type="button"
              title="Cancel"
              onClick={() => setIsModalOpen(false)}
            />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};

export default ForgotPassword;
