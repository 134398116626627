import React, { useEffect, useState } from "react";
import "../DashboardPage.scss";
import PrimaryButton from "../../../components/button/PrimaryButton";
import { copy, edit, link, payment } from "../../../assets/images/project-images";
import ModalWrapper from "../../../components/modal/ModalWrapper";
import InputLabelField from "../../../components/input-label-field/InputLabelField";
import { useForm } from "react-hook-form";
import TwillioVideo from "./Video";
import { useSelector } from "react-redux";
import axios from "../../../libs/axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { onSucess } from "../../../libs/onSuccess";
import { onError } from "../../../libs/errorLib";
import InputLabelField1 from "../../../components/input-label-field/inputLabelField1";
import io from "socket.io-client";
import config from "../../../config";
const socket = io(config.SOCKET_URL);
const DashboardPage = () => {
  const { user } = useSelector(({ auth }) => auth);
  const [copyText, setCopy] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => console.log(data);
  const [loader, setloader] = useState(false);
  const [rpm, setrpm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [userId,setUserId]=useState(null)
  const [token,setToken]=useState(null)
  const [name,setName]=useState(null)
  const [fname,setFullName]=useState(null)
  const handleCopy = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };
  const handleChange = (e) => {
    setrpm(e.target.value);
  };

  const getRPM = async () => {
    try {
      let data = await axios.get(`/users/getRPM/${user.id}`);
      setrpm(data.rpm);
    } catch (error) {}
  };

  useEffect(() => {
    getRPM();
  }, []);
  useEffect(() => {
    const lawyerId = user.id; // Replace with the lawyer ID

    // Send lawyer ID to the server to establish the connection
    socket.emit('loginLawyer', lawyerId);

    // Listen for incoming messages from users
    socket.on('receiveMessage', (data) => {
      const { userId:user,token:tk,name:n,fullName } = data;
      setUserId(user)
      setToken(tk)
      setName(n)
      setIsAcceptModalOpen(true)
      setFullName(fullName)
      // alert(`Message received from User ${userId}: ${message}`);
      // Display the message on the lawyer screen

      // Simulating a response from the lawyer to the user
      // const isAccepted = false; // Replace with the lawyer's response
      // socket.emit('respondToUser', { lawyerId, userId, isAccepted,token,name });
    });

    return () => {
      socket.off('receiveMessage');
    };
  }, []);
  const sendResponse=(accepted)=>{
    const isAccepted = accepted;
    const lawyerId = user.id; // Replace with the lawyer ID

    socket.emit('respondToUser', { lawyerId, userId, isAccepted,token,name });

  }
  return (
    <>
       {isAcceptModalOpen && (
        <ModalWrapper
          isModalOpen={isAcceptModalOpen}
          setIsModalOpen={setIsAcceptModalOpen}
          className="rate-per-minute-modal call-modal"
          Modalfooter={[
            <div className="modal-footer-buttons">
              <PrimaryButton
                onClick={() => {
                  sendResponse(true)
                  setIsAcceptModalOpen(false);
                  
                }}
                title="yes"
              />
              <PrimaryButton
                onClick={() => {
                    sendResponse(false)
                  setIsAcceptModalOpen(false)
                }}
                title="cancel"
                className="secondary-button"
              />
            </div>,
          ]}
        >
          <h5 className="font-semibold text-lg darkBlue mb-3">Accept</h5>
          <h3 className="blue font-light text-3xl">
          {fname}
          </h3>
          <h5 className="font-semibold text-lg darkBlue mt-3">in the call?</h5>
        </ModalWrapper>
      )}
      <div className="dashboard-heading">
        <h2 className="darkGray font-light">
          Hello{" "}
          <span className="font-semibold">
            {user.first_name} {user.last_name}
          </span>
        </h2>
      </div>
      <div className="dashboard-cards">
        <div className="meeting-link-card">
          <div className="left-content">
            <span className="font-normal text-lg blue">
              <img src={link} alt="link" />
              My Room:
            </span>
            <p className="darkBlue font-semibold text-lg">{user.meeting_link}</p>
          </div>
          <CopyToClipboard text={user.meeting_link} onCopy={handleCopy}>
            <div className={`right-content ${copyText ? "copy-text" : ""}`}>
              {copyText ? (
                "Copied"
              ) : (
                <button>
                  <img src={copy} alt="copy" />
                </button>
              )}
            </div>
          </CopyToClipboard>
        </div>
        <div className="meeting-link-card">
          <div className="left-content">
            <span className="font-normal text-lg blue">
              <img src={payment} alt="rate" />
              Rate per minute:
            </span>
            <p className="darkGray font-bold text-lg">{rpm} USD</p>
          </div>
          <div className="right-content" onClick={() => setIsModalOpen(true)}>
            <img src={edit} alt="edit" />
          </div>
        </div>
      </div>
      <TwillioVideo />

      {isModalOpen && (
        <ModalWrapper
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          className="rate-per-minute-modal"
          Modalfooter={[
            <div className="modal-footer-buttons">
              <PrimaryButton
                isloading={loader}
                onClick={async () => {
                  setloader(true);
                  if (rpm === "") {
                    setloader(false);
                    onError("RPM field is empty");
                    return;
                  }
                  try {
                    let data = await axios.patch(`/users/updateRPM/${user?.id}`, {
                      rpm,
                    });
                    setloader(false);
                    onSucess("Update Successfully");
                  } catch (error) {
                    setloader(false);
                    onError("Something Went Wrong");
                  }

                  setIsModalOpen(false);
                }}
                title="save"
              />
              <PrimaryButton
                onClick={() => setIsModalOpen(false)}
                title="cancel"
                className="secondary-button"
              />
            </div>,
          ]}
        >
          <h3 className="blue font-light mb-4 text-3xl">Rate edit</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-7 relative">
              <InputLabelField1
                handleChange={handleChange}
                label="Rate per minute"
                type="number"
                value={rpm}
                name="ratePerMinute"
                min="0"
              />
              <div className="select-field">
                <select name="rate" id="rate">
                  <option value="usd">USD</option>
                  <option value="eur">EUR</option>
                  <option value="pkr">PKR</option>
                  <option value="ind">IND</option>
                </select>
              </div>
            </div>
          </form>
        </ModalWrapper>
      )}
    </>
  );
};

export default DashboardPage;
