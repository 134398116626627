import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      width: '100%',
      display: 'flex',
      minHeight: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    spin
  />
);
const Loader = () =>  <div className='flex items-center justify-center'><Spin indicator={antIcon} /></div>;
export default Loader;