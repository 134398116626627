import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputLabelField from "../../components/input-label-field/InputLabelField";
import SelectLabelField from "../../components/select-label-field/SelectLabelField";
import { yupResolver } from "@hookform/resolvers/yup";
import { userDetailsSchema } from "../../schemas/validation";
import InputLabelField1 from "../../components/input-label-field/inputLabelField1";
import PrimaryButton from "../../components/button/PrimaryButton";
import axios from "../../libs/axios";
import { onSucess } from "../../libs/onSuccess";
import { onError } from "../../libs/errorLib";
export const selectLocation = ["lahore", "karachi", "multan"];
export const selectCurrency = ["USD", "PKR", "EUR", "IND"];

const BrandForm = ({ setisModalOpen, getBrandsData, image }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loader, setloader] = useState(false);

  const onSubmit = async (data) => {
    setloader(true);
    try {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", image);
      const response = await axios.post("/brand/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      onSucess(
        response?.data?.response?.DESCRIPTION
          ? response?.data?.response?.DESCRIPTION
          : "Card Added Successfully"
      );
      setisModalOpen(false);
      getBrandsData();
    } catch (error) {
      console.log(error?.response?.data?.response?.DESCRIPTION);
      onError(error?.response?.data?.response?.DESCRIPTION);
    }
    setloader(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <>
        <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
          <InputLabelField
            register={register}
            label="Brand Name"
            type="text"
            name="name"
            placeholder="Enter Brand Name"
            required={true}
          />
          <InputLabelField
            register={register}
            label="Discount"
            type="text"
            name="discount"
            placeholder="Enter Discount"
            required={true}
          />
        </div>
        <div className="flex flex-wrap sm:flex-nowrap gap-4 mb-4">
          <InputLabelField
            register={register}
            label="Discount Type"
            type="text"
            name="discountType"
            placeholder="Enter Discount Type"
            required={true}
          />
        </div>
        {Object.keys(errors).length > 0 && (
          <p style={{ textAlign: "center", color: "red", margin: "10px" }}>
            Fill all required fields
          </p>
        )}

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
          <PrimaryButton isLoading={loader} onClick={handleSubmit} type="submit" title="Save" />
          <PrimaryButton
            onClick={() => {
              setisModalOpen(false);
              setloader(false);
            }}
            title="cancel"
            className="secondary-button"
          />{" "}
        </div>
      </>
    </form>
  );
};

export default BrandForm;
